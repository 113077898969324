






import { Component, Vue } from 'vue-property-decorator'
@Component({
  computed: {
    env(): string {
      return process.env.NODE_ENV
    },
  },
  created() {
    console.log(process.env.NODE_ENV)
  },
})
export default class Home extends Vue {}
